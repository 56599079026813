<template>
  <sm-editable-item
    v-model="form"
    :controllerName="controllerName"
    :isLoading="item.isLoading"
    :breadcrumbs="breadcrumbs"
    :pageHeader="pageHeader"
    :fields="fields"
    :disabledSaveButton="isEqual"
    @save="onSave('DatabasesWithInsurances')"
    @cancel="onCancel('DatabasesWithInsurances')"
  />
</template>

<script>
// mixins
import editableListItemEdit from '@/mixins/editableListItemEdit.js';
// vuex
import { createNamespacedHelpers } from 'vuex';
const { mapState, mapActions } = createNamespacedHelpers('editableList');
// components
import SmEditableItem from '@/components/views/SmEditableItem.vue';

export default {
  name: 'DatabaseWithInsurancesEdit',

  components: {
    SmEditableItem,
  },

  mixins: [editableListItemEdit],

  data() {
    return {
      controllerName: 'DatabasesWithInsurances',
      pageHeader: 'Редактирование базы данных со страхованием',
      breadcrumbs: [
        {
          text: 'Мониторинг',
          route: { name: 'MonitoringMain' },
        },
        {
          text: 'Базы данных со страхованием',
          route: { name: 'DatabasesWithInsurances' },
        },
        {
          text: 'Редактирование базы данных со страхованием',
        },
      ],
      form: {},
    };
  },

  computed: {
    ...mapState(['item']),

    fields() {
      return [
        {
          form: [
            {
              type: 'text',
              key: 'dbName',
              label: 'Имя базы данных',
            },
          ],
        },
      ];
    },
  },

  created() {
    this.getItem({ name: this.controllerName, id: this.id }).then(() => {
      this.form = this.lodash.cloneDeep(this.item.data);
    });
  },

  methods: {
    ...mapActions(['getItem', 'updateItem']),
  },
};
</script>
